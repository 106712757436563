import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { ReplaySubject, Subscription, switchMap } from "rxjs";
import { TenantAuthorisationService } from "../../tenant/module/tenant-authorisation.service";
import { FeaturePermission } from "../feature-permission";

@Directive({
    selector: "[appIfHasPermission]",
    standalone: false,
})
export class IfHasPermissionDirective implements OnInit, OnDestroy {
    @Input("appIfHasPermission") public set permission(value: FeaturePermission) {
        this.requiredPermission.next(value);
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("appIfHasPermissionElse") public elseTemplateRef?: TemplateRef<any>;

    private requiredPermission = new ReplaySubject<FeaturePermission>(1);
    private sub?: Subscription;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private authorisationService: TenantAuthorisationService,
    ) {}

    public ngOnInit() {
        this.sub = this.requiredPermission
            .pipe(switchMap((permission) => this.authorisationService.hasPermission(permission)))
            .subscribe((hasPermission) => {
                if (hasPermission) {
                    this.viewContainerRef.createEmbeddedView(this.templateRef);
                } else if (this.elseTemplateRef) {
                    this.viewContainerRef.createEmbeddedView(this.elseTemplateRef);
                } else {
                    this.viewContainerRef.clear();
                }
            });
    }

    public ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
