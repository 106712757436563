import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
    selector: "[appValuesEqual]",
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: ValuesEqualValidatorDirective,
            multi: true,
        },
    ],
    standalone: false,
})
export class ValuesEqualValidatorDirective implements Validator {
    @Input("appValuesEqual") public otherFieldName?: string;

    public validate(control: AbstractControl): ValidationErrors | null {
        if (!this.otherFieldName) {
            throw new Error("No comparison field specified");
        }

        let group = control.parent;
        let otherControl: AbstractControl | undefined;

        while (group && !Array.isArray(group.controls) && !otherControl) {
            otherControl = group.controls[this.otherFieldName];
            group = group.parent;
        }

        if (!otherControl) {
            throw new Error(`Unable to find control with name: ${this.otherFieldName}`);
        }

        return control.value === otherControl.value ? null : { valuesEqual: true };
    }
}
