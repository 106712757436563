import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "app/auth/authentication.service";
import { DialogService } from "app/common-ux/dialog/dialog.service";
import { TenantService } from "app/tenant/module/tenant.service";

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.component.html",
    styleUrls: ["./reset-password.component.scss"],
    standalone: false,
})
export class ResetPasswordComponent implements OnInit {
    @ViewChild("resetForm") resetForm!: NgForm;
    public model = {
        newPassword: "",
        confirmPassword: "",
    };
    public resetState = {
        isInvalid: false,
        invalidReason: "",
        isResetting: false,
        resetSuccessfully: false,
    };

    public isInvalidLink = false;
    public resetSending = false;
    public resetSent = false;

    constructor(
        private authenticationService: AuthenticationService,
        private dialogService: DialogService,
        private tenantService: TenantService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    public ngOnInit() {
        const expiryParam = this.route.snapshot.queryParams.expiry;
        if (!expiryParam) {
            return;
        }

        const expiry = new Date(expiryParam);
        this.isInvalidLink = expiry < new Date();
    }

    public async resetPassword() {
        try {
            this.resetState.isInvalid = false;
            this.resetState.isResetting = true;
            const email = this.route.snapshot.queryParams.email;
            const token = this.route.snapshot.queryParams.token;
            await this.authenticationService.resetPassword(email, token, this.model.newPassword);
            this.resetForm.form.disable();
            this.resetState.resetSuccessfully = true;

            const user = await this.authenticationService.login(email, this.model.newPassword);
            const redirect = this.route.snapshot.queryParams.redirect;
            if (redirect) {
                await this.router.navigateByUrl(redirect);
            } else {
                const url = this.tenantService.getDefaultUrlForUser(user);
                await this.router.navigateByUrl(url);
            }

            this.dialogService.success("Presto!");
        } catch (e) {
            const response = e as HttpErrorResponse;
            if (response.status === 400) {
                this.isInvalidLink = true;
            } else {
                this.resetState.isInvalid = true;
                this.resetState.invalidReason = "Unknown error when resetting password";
            }
        } finally {
            this.resetState.isResetting = false;
        }
    }

    public async sendResetLink() {
        try {
            this.resetSending = true;
            const email = this.route.snapshot.queryParams.email;
            const redirect = this.route.snapshot.queryParams.redirect;
            await this.authenticationService.requestPasswordReset(email, redirect);
            this.resetSent = true;
        } finally {
            this.resetSending = false;
        }
    }
}
