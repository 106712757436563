import { Component, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthenticationService } from "app/auth/authentication.service";

@Component({
    selector: "app-forgot-password",
    templateUrl: "./forgot-password.component.html",
    styleUrls: ["./forgot-password.component.scss"],
    standalone: false,
})
export class ForgotPasswordComponent {
    @ViewChild("resetForm", { static: true }) resetForm!: NgForm;
    public email = "";
    public resetState = {
        isInvalid: false,
        isResetting: false,
        sentSuccessfully: false,
    };

    constructor(private authenticationService: AuthenticationService) {}

    public async requestPasswordReset() {
        try {
            this.resetState.isInvalid = false;
            this.resetState.sentSuccessfully = false;

            this.resetState.isResetting = true;
            await this.authenticationService.requestPasswordReset(this.email);
            this.resetForm.form.disable();
            this.resetState.sentSuccessfully = true;
        } catch (e) {
            this.resetState.isInvalid = true;
        } finally {
            this.resetState.isResetting = false;
        }
    }
}
